import React from "react"
import { Link } from "gatsby"
import starIcon from "../assets/images/star-icon.png"
import Layout from "../components/App/Layout"

const NotFound = () => {
  return (
    <Layout page="Page Not Found">
      <br />
      <section className="solutions-area pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">
              <img src={starIcon} alt="star" />
              Oops!
            </span>
            <h2>Page Not Found</h2>
            <p>
              The page you are looking for might have been removed had its name
              changed or is temporarily unavailable.
            </p>
            <p>
              <Link className="default-btn" to="/">
                <i className="flaticon-right"></i> Home <span></span>
              </Link>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFound
